@import "npm:glightbox/dist/css/glightbox.css";

/* *********************************************
   *************variables and resets************
   ********************************************* */

:root {
  /* *********************** 
     Color scheme variables 
     *********************** */

  --c-dark: #334733;
  --c-medium: #446f44;
  --c-light: #e8eee6;
  --c-light-trans: rgba(232 238 230 / 85%);
  --c-white: #f0f2f0;
  --c-dark-accent: #a54e15;
  --c-light-accent: #fb6d0e;
  --c-error: #ff0c0c;
  --c-success: rgb(23 228 0);
  --c-transparent: rgba(0 0 0 / 60%);
  --c-placeholder: #898989;

  /* font variables */

  --ff-serif: "Neuton", serif;
  --ff-sans: "Work Sans", sans-serif;
  --ff-p-sans: "Open Sans", sans-serif;
  --fw-thin: 300;
  --fw-normal: 400;
  --fw-bold: 800;
  --fs-xlarge: clamp(5.75rem, 4.1374rem + 4.4872vw, 7.5rem);
  --fs-large: clamp(2.25rem, -0.5144rem + 7.6923vw, 5.25rem);
  --fs-medium: clamp(2.25rem, 0.6374rem + 4.4872vw, 4rem);
  --fs-small: clamp(1.625rem, 1.0491rem + 1.6026vw, 2.125rem);
  --fs-xsmall: clamp(0.8rem, 0.6157rem + 0.5128vw, 1rem);
  --fs-p: clamp(1rem, 0.712rem + 0.8013vw, 1.3125rem);

  /* top margin variable to prevent collapsing margins in <p>, <h*> tags, with .flow class
     redefine variable at location to override */
  --flow-space: 0.875em;
  --spacer-max-width: 70rem;
  --spacer: 1rem;
  --spacer-medium: calc(var(--spacer) * 2);
  --spacer-large: calc(var(--spacer) * 3);
  --spacer-xlarge: calc(var(--spacer) * 5);
  --spacer-section: calc(var(--spacer) * 8);
  --border-radius: 0.25rem;
  --shadow: 12px 16px 10px rgba(82 129 82 30%);
  --shadow-img: 6px 8px 5px rgba(82 129 82 30%);

  /* @media breakpoints */
  --breakpoint-large: 78.125rem;
  --test1: calc((100vw - var(--spacer-max-width)) / 2);
  --test2: calc((3 * var(--spacer-max-width) + 2));
  --test3: calc((var(--spacer-max-width) + 2 * 3));
  --test4: calc(var(--spacer-max-width) + 2 * 3);
  --test5: calc((var(--spacer-max-width) + 2));
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:target {
  scroll-margin-top: var(--spacer-section);
}

a {
  display: block;
  font-family: var(--ff-sans);
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/***********************************************
 ****************general styling****************
 ***********************************************/

/* Prevents collapsing margins and creates consistent margin of child elements. */

/* .flow > * + * {
  margin-top: var(--flow-space, 0.875em);
} */

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--c-white);
}

/* *:focus {
  outline: 3px solid var(--c-dark-accent);
} */

p {
  /* font-size: clamp(1rem, 0.9250rem + 0.3333vw, 1.1rem); */
  font-size: var(--fs-p);
  font-family: var(--ff-p-sans);
  line-height: 1.6;
  font-weight: var(--fw-normal);
}

/*  If this ever gets adopted. Removes additional height from text lines */

/* h1, h2, h3 {
  leading-trim: both;
} */

a:hover {
  color: var(--c-dark-accent);
}

.article-wrapper,
.hero,
.title-group {
  margin-bottom: var(--spacer-section);
}

.main-content > *,
.section-title,
.title-group {
  width: 100%;
  max-width: 70rem;
  position: relative;
}

.section-title {
  font-family: var(--ff-sans);
  font-size: var(--fs-large);
  font-weight: var(--fw-thin);
  color: var(--c-medium);
  align-self: start;
  margin-bottom: var(--spacer-section);
}

.title-group {
  text-align: center;
}

.title-group > .section-title {
  margin-bottom: 0;
}

.section-subtitle {
  --flow-space: 0;

  font-family: var(--ff-serif);
  color: var(--c-dark-accent);
  font-style: italic;
  font-weight: var(--fw-normal);
  font-size: var(--fs-small);

  /* font-size: clamp(1rem, 0.3438rem + 2.9167vw, 1.875rem); */
}
@media (max-width: 1200px) {
  .article-wrapper {
    padding: 0 var(--spacer-medium);
  }
}

@media (max-width: 1100px) {
  /* .card:not(:last-child) {
    margin-bottom: var(--spacer-xlarge);
  } */
  .card-wrapper > .cardless {
    margin-bottom: 0;
  }
}

@media (max-width: 780px) {
  .article-wrapper {
    margin-bottom: var(--spacer-large);
  }

  .hero,
  .title-group {
    margin-bottom: var(--spacer-medium);
  }

  .section-title {
    margin-bottom: var(--spacer-xlarge);
  }

  /* .card:not(:last-child) {
    margin-bottom: var(--spacer-large);
  } */

  .card-wrapper > .cardless {
    margin-bottom: 0;
  }
}

/***********************************************
 *************accessibility styling*************
 ***********************************************/

/* Hides additional descriptive <span> text so that it is only visible to screen readers */
.screen-reader {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

/***********************************************
 *****************Navbar styling***************
 ***********************************************/

.page-header {
  position: fixed;
  z-index: 9999;
  width: 100%;
  left: 0;
  top: 0;
  transition: transform 400ms ease;
}

.navbar {
  --nav-spacer: calc(((100vw - var(--spacer-max-width)) / 2));

  text-align: center;
  width: 100%;
  background-color: var(--c-dark);
  display: grid;
  grid-template-columns:
    minmax(var(--spacer), var(--nav-spacer)) auto minmax(6.25rem, 3fr)
    minmax(var(--spacer), var(--nav-spacer));
  grid-template-rows: auto;
  font-family: var(--ff-sans);
}

/* @media (max-width: 1300px) {
  .navbar {
    --nav-spacer: 0.5fr;
  }
} */

.company-logo {
  grid-column: 2/3;
  margin: var(--spacer-medium) 0;
}

.company-logo > img {
  width: 198px;
  height: 34px;
}

.nav-menu {
  grid-column: 3/4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  background-color: var(--c-dark);
  width: 100%;
}

.nav-item {
  padding-left: var(--spacer-medium);
}

.nav-link,
.footer-link,
.floating-link {
  color: var(--c-white);
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  font-size: var(--fs-p);
}

.nav-link:hover {
  color: var(--c-light-accent);
}

/* .nav-link::after {
  content: "";
  display: block;
  height: 0.125rem;
  background: var(--c-dark-accent);
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav-link:hover::after {
  opacity: 1;
} */

/* Nav toggle displays only with small screen media query and JS is active */
.nav-toggle {
  background-image: url("../images/menubars.svg");
  background-color: transparent;
  background-size: 100% 100%;
  height: 2.5em;
  width: 2.5em;
  border: none;
  display: none;
}

.nav-toggle.is-active {
  background-image: url("../images/menucross.svg");
}

@media (max-width: 780px) {
  .js .nav-toggle {
    display: block;
    position: relative;
    margin-right: var(--spacer);
  }

  .company-logo {
    margin: var(--spacer) 0;
    margin-left: var(--spacer);
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }

  .nav-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: inline-block;
    text-align: start;
  }

  .nav-link {
    display: inline-block;
    padding: var(--spacer-medium) 0;
    margin-left: var(--spacer-medium);
    width: 100%;
  }

  .nav-link:hover::after {
    opacity: 0;
  }

  .nav-item {
    padding: 0;
  }

  .nav-item:not(:last-child)::after {
    content: "";
    display: block;
    height: 2px;
    background: var(--c-white);
    position: absolute;
    left: var(--spacer-medium);
    right: 0;
    width: 80%;
    opacity: 0.2;
  }

  /* Displays hamburger menu ONLY when JS is active on small screens */
  .page-header {
    position: relative;
  }

  .js .page-header {
    position: fixed;
  }

  .js .nav-menu {
    display: none;
  }

  @keyframes drop-animate {
    0% {
      transform: scaleY(0);
      transform-origin: top;
      opacity: 0;
    }

    100% {
      transform: scaleY(1);
      transform-origin: top;
      opacity: 1;
    }
  }

  .nav-menu.is-active {
    display: inline-block;
    background-color: var(--c-dark);
    animation: drop-animate 250ms;
  }

  .is-not-active {
    filter: grayscale(100%);
  }
}

.scroll-down .page-header {
  transform: translate3d(0, -1000%, 0);
}

.scroll-up .page-header {
  transform: none;
}

/***********************************************
 *****************hero styling******************
 ***********************************************/

.hero {
  height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(var(--c-transparent), var(--c-transparent)),
    top 0% left 10% / cover no-repeat
      url("../images/banners/20201022_111032_cropped.webp");
}

/* .hero {
  background: linear-gradient(var(--c-transparent), var(--c-transparent)),
    top 0% left 10% / cover no-repeat
      url(../images/banners/20201022_111032_cropped.webp);

} */

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: var(--spacer-max-width);
  max-width: 100%;
  padding-left: var(--spacer-medium);
  padding-right: var(--spacer-medium);
}

.hero-title {
  position: relative;
  color: var(--c-white);
  text-align: center;
  font-family: var(--ff-sans);
  font-weight: var(--fw-thin);
  font-size: var(--fs-large);
}

.hero-sub {
  position: relative;
  color: var(--c-white);
  text-align: center;
  font-family: var(--ff-serif);
  font-weight: var(--fw-normal);
  font-size: var(--fs-small);
}

.hero-cta {
  margin-top: var(--spacer-xlarge);
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  font-size: var(--fs-p);
}

.hero-cta > * {
  text-decoration: none;
  padding: var(--spacer);
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
}

.hero-cta > *:nth-child(1) {
  text-transform: uppercase;
  background: var(--c-dark-accent);
  color: var(--c-white);
  margin-right: var(--spacer-large);
}

.hero-cta > *:nth-child(2) {
  background: var(--c-white);
  color: var(--c-dark);
  font-size: var(--fs-p);
}

@media (max-width: 575px) {
  .hero-cta {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hero-cta > * {
    width: 12rem;
  }

  .hero-cta > *:nth-child(1) {
    width: 12rem;
    margin-right: 0;
    margin-bottom: var(--spacer-medium);
  }
}

/***********************************************
 **********hero/nav fade in animation***********
 ***********************************************/

.fade-in {
  animation: fade-in ease-in 1.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/***********************************************
 *****************card styling******************
 ***********************************************/

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.article-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.card-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: var(--spacer-xlarge);
}

.card {
  background-color: var(--c-medium);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
  padding: var(--spacer-medium);
  text-align: left;
  color: var(--c-white);
  min-height: 25rem;
  width: 21rem;
  position: relative;
}

.card-rec {
  min-height: 32rem;
}

.card-rec > img {
  align-self: center;
  border-radius: 50%;
}

/* .card:nth-child(even) {
  margin-left: var(--spacer-medium);
  margin-right: var(--spacer-medium);
} */

.card-icons {
  margin-top: var(--spacer-medium);
}

.card-caption {
  font-family: var(--ff-sans);
  color: var(--c-white);
  font-size: var(--fs-small);
  font-weight: var(--fw-normal);
  margin: var(--spacer-large) 0 var(--spacer-medium) 0;
}

.card-text {
  margin-bottom: var(--spacer-medium);
}

.card-text li {
  font-size: var(--fs-p);
  font-family: var(--ff-p-sans);
  line-height: 1.6;
  font-weight: var(--fw-normal);
  margin-bottom: var(--spacer);
}

.card-icon-container {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(var(--spacer-medium), -50%);
  border: 0.5rem solid var(--c-medium);
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  overflow: hidden;
  background-color: var(--c-white);
}

.card-icon {
  margin: auto;
  font-size: 4rem;
  color: var(--c-medium);
}

.card-img {
  overflow: hidden;
  border-radius: var(--border-radius);
  height: 12.5rem;
  width: 12.5rem;
  max-width: 100vw;
  box-shadow: var(--shadow);
  object-fit: cover;
  margin-bottom: var(--spacer-medium);
}

.card-name {
  font-size: var(--fs-xsmall);
  font-family: var(--ff-sans);
  font-weight: var(--fw-thin);
  font-style: italic;
  margin-bottom: var(--spacer);
  text-transform: capitalize;
}

.card-title {
  font-size: var(--fs-medium);
  font-family: var(--ff-serif);
}

.scrolling-bg {
  --bg-min-height: calc(938 / 3);

  min-height: var(--bg-min-height);
  justify-content: center;
}

.main-content > .scrolling-bg {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.scrolling-bg::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(var(--c-transparent), var(--c-transparent)),
    bottom 30% left 0 / cover no-repeat
      url("../images/banners/20210405_170207.webp");
  background-attachment: fixed;
}

.scrolling-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: var(--spacer-xlarge);
  max-width: var(--spacer-max-width);
}

.cardless {
  position: relative;
  background: var(--c-light);
  color: var(--c-dark);
  box-shadow: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.cardless:not(:first-child) {
  color: var(--c-dark-accent);
}

.about-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: var(--spacer-xlarge);
  margin-top: var(--spacer);
  z-index: 10;
  color: var(--c-dark);
}

.about-wrapper > .card-img {
  border-radius: 50%;
  height: 20rem;
  width: 20rem;
  max-width: 90vw;
  grid-row: 1 / 3;
  margin: 0;
}

.about-wrapper > .card-caption {
  color: var(--c-dark);
  margin: 0;
}

.hidden-desktop {
  color: var(--c-dark);
  display: none;
}

.about-wrapper > .card-text {
  margin: 0;
}

.card ul {
  margin: 0;
  list-style: none;
}

.card li::before {
  background-color: var(--c-white);
  border-radius: 50%;
  content: "";
  display: inline-block;
  margin-right: 0.625rem;
  margin-bottom: 0.125rem;
  height: 0.375rem;
  width: 0.375rem;
}

/* .card-text li::first-letter {
  text-transform: uppercase;
} */

@media (max-width: 1200px) {
  .about-wrapper {
    /* display: flex;
    flex-direction: column; */
    width: 100%;
  }

  .about-wrapper > *:not(:last-child) {
    margin-bottom: var(--spacer-medium);
  }
}
@media (max-width: 1100px) {
  .about-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .about-wrapper > .card-img:first-child {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .about-wrapper > .card-caption {
    margin-bottom: 0;
  }

  .hidden-desktop {
    display: inline;
  }

  .hidden-mobile {
    display: none;
  }

  .article-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: center;
    width: 100%;
    padding-left: var(--spacer-large);
    padding-right: var(--spacer-large);
  }

  .card:nth-child(even) {
    margin-left: 0;
    margin-right: 0;
  }

  .card-wrapper {
    /* flex-direction: column; */
    align-items: flex-start;
    text-align: left;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
  }

  /* .scrolling-bg {
    min-height: 140vh;
  } */
}

@media (max-width: 990px) {
  .scrolling-container {
    grid-template-columns: 1fr 1fr;
  }

  .cardless:first-child {
    grid-column: 1/-1;
  }

  .card ul {
    padding-left: var(--spacer);
  }

  .card li::before {
    height: 6px;
    width: 6px;
  }
}

@media (max-width: 780px) {
  .card-icons {
    margin-top: 0;
  }

  .card {
    width: 100%;
    max-width: 21rem;
    min-height: 22rem;
  }

  .card-icons > .card:not(:first-child) {
    margin-top: var(--spacer-medium);
  }

  .card-wrapper {
    flex-flow: nowrap;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: space-evenly;
    width: 100%;
    gap: var(--spacer-large);
  }

  .scrolling-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 0;
    height: 100%;
  }

  .scrolling-bg {
    max-height: 80rem;
    justify-content: center;
  }

  .cardless {
    max-width: 21rem;
    min-height: 21rem;
  }

  .cardless:first-child {
    min-height: var(--spacer-xlarge);
  }

  .cardless > .card-text {
    margin: 0;
  }
}

@media (max-width: 575px) {
  .card {
    width: 100%;
  }

  .article-wrapper {
    padding-left: var(--spacer);
    padding-right: var(--spacer);
  }
}

/***********************************************
 *************contact form styling**************
 ***********************************************/

.hidden {
  display: none;
}

.card-contact {
  background-color: var(--c-white);
  padding: 0;
  color: var(--c-medium);
  width: 100%;
  max-width: 100%;
  box-shadow: none;
}

.contact-form {
  display: grid;
  width: 100%;
  font-size: var(--fs-p);
  font-family: var(--ff-sans);
  align-items: center;
  gap: var(--spacer);
}

.label-group {
  display: flex;
  gap: var(--spacer);
  flex-direction: column;
  width: 100%;
}

.form-group {
  display: grid;
  gap: var(--spacer-medium);
  grid-template-columns: repeat(auto-fit, minmax(15.625rem, 1fr));
  width: 100%;
}

.icon-group {
  position: relative;
  display: flex;
  width: 100%;
}

.grid-span {
  text-align: center;
}

.form-input {
  font-size: var(--fs-p);
  font-family: var(--ff-p-sans);
  border-radius: var(--border-radius);
  border-width: 0.125rem;
  border-color: var(--c-medium);
  border-style: solid;
  padding: var(--spacer);
  background-color: var(--c-white);
  color: var(--c-medium);
  width: 100%;
}

.label-group .card-caption {
  color: var(--c-medium);
  margin: 0;
}

.form-input:focus {
  background-color: var(--c-light);
  outline: 3px solid var(--c-medium);
  color: var(--c-dark);
}

.label-group .form-message,
.form-error {
  display: block;
  color: var(--c-medium);
  font-style: italic;
  height: var(--spacer-medium);
}

.label-group.error .form-message {
  color: var(--c-error);
}

.label-group.error .form-input {
  border-color: var(--c-error);
}

.label-group.success .form-input {
  border-color: var(--c-success);
}

.label-group.error .form-input:focus {
  outline: 3px solid var(--c-error);
}

.label-group.success .form-input:focus {
  outline: 3px solid var(--c-success);
}

.form-icon {
  position: absolute;
  top: 50%;
  right: 0.3rem;
  transform: translateY(-50%);
}

.icon-error {
  color: var(--c-error);
  display: none;
}

.icon-success {
  color: var(--c-success);
  display: none;
}

.label-group.success .icon-success {
  display: block;
}

.label-group.error .icon-error {
  display: block;
}

.icon-group svg {
  height: calc(var(--fs-p) + var(--fs-p));
  width: calc(var(--fs-p) + var(--fs-p));
}

.textarea {
  text-align: start;
}

.contact-form button {
  cursor: pointer;
  border-radius: var(--border-radius);
  color: var(--c-medium);
  font-family: var(--ff-sans);
  font-size: var(--fs-small);
  padding: var(--spacer);
  justify-self: center;
  width: 33.5rem;
}

/* .button {
  display: inline-block;
  padding: var(--spacer);
  background: var(--c-medium);
  cursor: pointer;
  border-radius: var(--border-radius);
  border: 1px solid var(--c-dark);
  color: var(--c-white);
} */

/* #submitted-files {
  display: none;
} */

/* .dropzone-previews {
  padding-top: var(--spacer-large) !important;
  background-color: var(--c-white) !important;
}

.dropzone .dz-preview.dz-image-preview {
  background-color: var(--c-white) !important;
} */

/* .dz-preview button {
    position: absolute;
    top:-3em;
    width: 100%;
    left: 0;
    right: 0;
    background: var(--c-medium);
    color: var(--c-white);
} */

/* .dz-preview button:hover {
    background: var(--c-white) !important;
    color: var(--c-dark) !important;
    border: 2px solid var(--c-dark);
  } */

.button:hover {
  background: var(--c-white);
  color: var(--c-dark);
}

@media (max-width: 575px) {
  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .card-contact {
    padding-inline: var(--spacer);
  }

  .contact-form button {
    text-align: center;
    width: 100%;
  }

  .grid-span {
    text-align: left;
  }

  .label-group .form-message,
  .form-error {
    height: var(--spacer);
  }

  .form-group {
    gap: var(--spacer);
  }
}

/* ********************************************* 
   *************Submit page syling************** 
   ********************************************* */

.submit-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.submit-body {
  font-family: var(--ff-sans);
  background: var(--c-dark);
  color: var(--c-white);
  overflow: hidden;
}

.submit-header {
  font-size: var(--fs-small);
}

.submit-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 21rem;
  padding: var(--spacer-medium);
  background: var(--c-white);
  color: var(--c-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  gap: var(--spacer);
}

#back-link {
  margin: 0;
  text-decoration: none;
  font-weight: var(--fw-bold);
  color: var(--c-medium);
}

#back-link svg {
  position: relative;
  top: 2px;
}

#back-link:hover,
#back-link:focus {
  text-decoration: underline;
  text-underline-offset: 0.125rem;
  text-decoration-color: var(--c-light-accent);
}

#back-link:hover svg path {
  fill: var(--c-medium);
}

/***********************************************
 ***************portfolio styling***************
 ***********************************************/

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: 200px 200px;
  grid-auto-flow: dense;
  gap: var(--spacer-medium);
  max-width: var(--spacer-max-width);
  border-radius: var(--border-radius);
  width: 100%;
}

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
}

@media (min-width: 480px) {
  .gallery-item:first-child {
    grid-area: 1 / 1 / span 2 / span 2;
  }

  .gallery-item:nth-child(3n) {
    grid-column: span 2;
  }

  .gallery-item:nth-child(6n) {
    grid-column: span 2;
    grid-row: span 2;
  }
}

/***********************************************
 ****************footer styling*****************
 ***********************************************/

.footer-wrapper {
  padding: var(--spacer-large);
  background-color: var(--c-dark);
  color: var(--c-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.footer-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacer-large);
  margin-block-end: var(--spacer-large);
  max-width: var(--spacer-max-width);
}

.footer-title {
  font-family: var(--ff-sans);
  color: var(--c-white);
  font-size: var(--fs-small);
}

.footer-list,
.footer-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  gap: var(--spacer-medium);
  position: relative;
}

.footer-item:not(:last-child) ::after {
  content: "";
  display: block;
  height: 1px;
  background: var(--c-white);
  position: absolute;
  bottom: calc(var(--spacer) * -1);
  left: 0;
  width: 100%;
  overflow: hidden;
}

.icon {
  font-size: var(--fs-p);
  margin-right: var(--spacer);
}

.footer-link {
  display: flex;
  align-items: center;
}

.credit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-large);
  font-size: var(--fs-xsmall);
}

@media (max-width: 780px) {
  .footer-wrapper {
    padding: var(--spacer-large) 0;
  }

  .footer-content {
    flex-direction: column;
    gap: var(--spacer-medium);
  }

  .credit {
    flex-direction: column;
    gap: var(--spacer);
  }

  .footer-item:not(:last-child)::after {
    bottom: calc(var(--spacer) * -0.5);
  }
}

.floating-wrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin: 0 auto;
  padding: 0;
  display: inline-block;
  text-align: center;
  color: var(--c-white);
  text-transform: uppercase;
  transform: translate3d(-50%, 1000%, 0);
  transition: transform 400ms ease;
  z-index: 100;
}

.floating-list {
  list-style: none;
  padding: var(--spacer) 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacer-large);
  align-items: center;
}

.floating-item {
  background-color: var(--c-dark);
  text-align: center;
  border-radius: var(--border-radius);
  padding: var(--spacer);
}

.floating-item:hover {
  background-color: var(--c-dark-accent);
}

.floating-link:hover {
  color: var(--c-white);
}

.scroll-down .floating-wrapper {
  transform: translateX(-50%);
}

.scroll-up .floating-wrapper {
  transform: translate3d(-50%, 1000%, 0);
}
