.glightbox-container {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  backface-visibility: hidden;
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999999 !important;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: relative;
  overflow: hidden;
}

.glightbox-container .gslider {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: transform .4s, -webkit-transform .4s;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  display: flex !important;
}

.glightbox-container .gslide {
  width: 100%;
  opacity: 1;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe, .gslide video {
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
  border: none;
  outline: none !important;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  float: none;
  -webkit-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  min-width: 200px;
  border: none;
  outline: none;
  margin: auto;
  padding: 0;
  display: block;
}

.desc-top .gslide-image img, .desc-bottom .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img, .desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  transition: none;
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.gslide-video {
  max-width: 100vh;
  position: relative;
  width: 100% !important;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  margin: auto;
}

.gslide-video:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #ff000057;
  display: none;
  position: absolute;
}

.gslide-video.playing:before {
  display: none;
}

.gslide-video.fullscreen {
  min-width: 100%;
  height: 75vh;
  max-width: 100% !important;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  text-align: left;
  max-height: calc(100vh - 40px);
  max-width: 100%;
  background: #fff;
  overflow: auto;
}

.gslide-inline .ginlined-content {
  width: 100%;
  padding: 20px;
}

.gslide-inline .dragging {
  transition: none;
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.ginlined-content {
  opacity: 1;
  overflow: auto;
  display: block !important;
}

.gslide-external {
  width: 100%;
  min-width: 100%;
  max-height: 75vh;
  height: 100%;
  background: #fff;
  padding: 0;
  display: flex;
  overflow: auto;
}

.gslide-media {
  width: auto;
  display: flex;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-top .gslide-media, .desc-bottom .gslide-media {
  flex-direction: column;
  margin: 0 auto;
}

.gslide-description {
  flex: 1 0 100%;
  position: relative;
}

.gslide-description.description-left, .gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom, .gslide-description.description-top {
  width: 100%;
  margin: 0 auto;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description, .glightbox-button-hidden {
  display: none;
}

.glightbox-mobile .glightbox-container .gslide-description {
  width: 100%;
  max-height: 78vh;
  background: linear-gradient(#0000 0%, #000000bf 100%);
  padding: 19px 11px 50px;
  transition: opacity .3s linear;
  position: absolute;
  bottom: 0;
  height: auto !important;
  max-width: 100vw !important;
  order: 2 !important;
  overflow: auto !important;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: bold;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc string {
  color: #fff;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: .4;
}

.gdesc-open .gslide-media {
  opacity: .4;
  transition: opacity .5s;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  opacity: 1;
  transition: opacity .5s;
}

.greset {
  transition: all .3s;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  z-index: 9999;
  border: 2px solid #fff;
  border-right-color: #0000;
  border-radius: 50%;
  margin: 0 auto;
  animation: lightboxLoader .8s linear infinite;
  display: block;
  position: absolute;
  top: 47%;
  left: 0;
  right: 0;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  will-change: opacity;
  background: #000;
  position: fixed;
  top: -1px;
  left: 0;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gprev, .gnext, .gclose {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gprev svg, .gnext svg, .gclose svg {
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
  display: block;
}

.gprev.disabled, .gnext.disabled, .gclose.disabled {
  opacity: .1;
}

.gprev .garrow, .gnext .garrow, .gclose .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gnext, .glightbox-closing .gprev, .glightbox-closing .gclose {
  opacity: 0 !important;
}

.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  color: #000;
  margin-bottom: 19px;
  font-family: arial;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  margin-bottom: 0;
  font-family: arial;
  font-size: .86em;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gprev, .glightbox-clean .gnext, .glightbox-clean .gclose {
  background-color: #000000bf;
  border-radius: 4px;
}

.glightbox-clean .gprev path, .glightbox-clean .gnext path, .glightbox-clean .gclose path {
  fill: #fff;
}

.glightbox-clean .gprev {
  width: 40px;
  height: 50px;
  position: absolute;
  top: -100%;
  left: 30px;
}

.glightbox-clean .gnext {
  width: 40px;
  height: 50px;
  position: absolute;
  top: -100%;
  right: 30px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 15px;
  right: 10px;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

.gfadeIn {
  animation: gfadeIn .5s;
}

.gfadeOut {
  animation: gfadeOut .5s;
}

.gslideOutLeft {
  animation: gslideOutLeft .3s;
}

.gslideInLeft {
  animation: gslideInLeft .3s;
}

.gslideOutRight {
  animation: gslideOutRight .3s;
}

.gslideInRight {
  animation: gslideInRight .3s;
}

.gzoomIn {
  animation: gzoomIn .5s;
}

.gzoomOut {
  animation: gzoomOut .5s;
}

@keyframes lightboxLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes gfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes gslideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-60%, 0, 0);
  }

  to {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(-60%, 0, 0);
  }
}

@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    transform: translate3d(60%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(60%, 0, 0);
  }
}

@keyframes gzoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 1;
  }
}

@keyframes gzoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    flex-direction: row;
  }

  .glightbox-container .ginner-container.desc-top .gslide-description {
    order: 0;
  }

  .glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    order: 1;
  }

  .glightbox-container .ginner-container.desc-left .gslide-description {
    order: 0;
  }

  .glightbox-container .ginner-container.desc-left .gslide-image {
    order: 1;
  }

  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }

  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }

  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }

  .gslide-inline {
    max-height: 95vh;
  }

  .gslide-external {
    max-height: 100vh;
  }

  .gslide-description.description-left, .gslide-description.description-right {
    max-width: 275px;
  }

  .glightbox-open {
    height: auto;
  }

  .goverlay {
    background: #000000eb;
  }

  .glightbox-clean .gslide-media {
    box-shadow: 1px 2px 9px #000000a6;
  }

  .glightbox-clean .description-left .gdesc-inner, .glightbox-clean .description-right .gdesc-inner {
    height: 100%;
    position: absolute;
    overflow-y: auto;
  }

  .glightbox-clean .gprev, .glightbox-clean .gnext, .glightbox-clean .gclose {
    background-color: #00000052;
  }

  .glightbox-clean .gprev:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gclose:hover {
    background-color: #000000b3;
  }

  .glightbox-clean .gprev, .glightbox-clean .gnext {
    top: 45%;
  }
}

@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: .7;
    right: 20px;
  }
}

@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}

:root {
  --c-dark: #334733;
  --c-medium: #446f44;
  --c-light: #e8eee6;
  --c-light-trans: #e8eee6d9;
  --c-white: #f0f2f0;
  --c-dark-accent: #a54e15;
  --c-light-accent: #fb6d0e;
  --c-error: #ff0c0c;
  --c-success: #17e400;
  --c-transparent: #0009;
  --c-placeholder: #898989;
  --ff-serif: "Neuton", serif;
  --ff-sans: "Work Sans", sans-serif;
  --ff-p-sans: "Open Sans", sans-serif;
  --fw-thin: 300;
  --fw-normal: 400;
  --fw-bold: 800;
  --fs-xlarge: clamp(5.75rem, 4.1374rem + 4.4872vw, 7.5rem);
  --fs-large: clamp(2.25rem, -.5144rem + 7.6923vw, 5.25rem);
  --fs-medium: clamp(2.25rem, .6374rem + 4.4872vw, 4rem);
  --fs-small: clamp(1.625rem, 1.0491rem + 1.6026vw, 2.125rem);
  --fs-xsmall: clamp(.8rem, .6157rem + .5128vw, 1rem);
  --fs-p: clamp(1rem, .712rem + .8013vw, 1.3125rem);
  --flow-space: .875em;
  --spacer-max-width: 70rem;
  --spacer: 1rem;
  --spacer-medium: calc(var(--spacer)  * 2);
  --spacer-large: calc(var(--spacer)  * 3);
  --spacer-xlarge: calc(var(--spacer)  * 5);
  --spacer-section: calc(var(--spacer)  * 8);
  --border-radius: .25rem;
  --shadow: 12px 16px 10px rgba(82 129 82 30%);
  --shadow-img: 6px 8px 5px rgba(82 129 82 30%);
  --breakpoint-large: 78.125rem;
  --test1: calc((100vw - var(--spacer-max-width) )  / 2);
  --test2: calc((3 * var(--spacer-max-width)  + 2) );
  --test3: calc((var(--spacer-max-width)  + 2 * 3) );
  --test4: calc(var(--spacer-max-width)  + 2 * 3);
  --test5: calc((var(--spacer-max-width)  + 2) );
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:target {
  scroll-margin-top: var(--spacer-section);
}

a {
  font-family: var(--ff-sans);
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--c-white);
}

p {
  font-size: var(--fs-p);
  font-family: var(--ff-p-sans);
  line-height: 1.6;
  font-weight: var(--fw-normal);
}

a:hover {
  color: var(--c-dark-accent);
}

.article-wrapper, .hero, .title-group {
  margin-bottom: var(--spacer-section);
}

.main-content > *, .section-title, .title-group {
  width: 100%;
  max-width: 70rem;
  position: relative;
}

.section-title {
  font-family: var(--ff-sans);
  font-size: var(--fs-large);
  font-weight: var(--fw-thin);
  color: var(--c-medium);
  margin-bottom: var(--spacer-section);
  align-self: start;
}

.title-group {
  text-align: center;
}

.title-group > .section-title {
  margin-bottom: 0;
}

.section-subtitle {
  --flow-space: 0;
  font-family: var(--ff-serif);
  color: var(--c-dark-accent);
  font-style: italic;
  font-weight: var(--fw-normal);
  font-size: var(--fs-small);
}

@media (max-width: 1200px) {
  .article-wrapper {
    padding: 0 var(--spacer-medium);
  }
}

@media (max-width: 1100px) {
  .card-wrapper > .cardless {
    margin-bottom: 0;
  }
}

@media (max-width: 780px) {
  .article-wrapper {
    margin-bottom: var(--spacer-large);
  }

  .hero, .title-group {
    margin-bottom: var(--spacer-medium);
  }

  .section-title {
    margin-bottom: var(--spacer-xlarge);
  }

  .card-wrapper > .cardless {
    margin-bottom: 0;
  }
}

.screen-reader {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  border: none;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.page-header {
  z-index: 9999;
  width: 100%;
  transition: transform .4s;
  position: fixed;
  top: 0;
  left: 0;
}

.navbar {
  --nav-spacer: calc(((100vw - var(--spacer-max-width) )  / 2) );
  text-align: center;
  width: 100%;
  background-color: var(--c-dark);
  grid-template-columns: minmax(var(--spacer), var(--nav-spacer) ) auto minmax(6.25rem, 3fr) minmax(var(--spacer), var(--nav-spacer) );
  font-family: var(--ff-sans);
  grid-template-rows: auto;
  display: grid;
}

.company-logo {
  margin: var(--spacer-medium) 0;
  grid-column: 2 / 3;
}

.company-logo > img {
  width: 198px;
  height: 34px;
}

.nav-menu {
  background-color: var(--c-dark);
  width: 100%;
  grid-column: 3 / 4;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  display: flex;
}

.nav-item {
  padding-left: var(--spacer-medium);
}

.nav-link, .footer-link, .floating-link {
  color: var(--c-white);
  text-transform: uppercase;
  font-size: var(--fs-p);
  text-decoration: none;
  position: relative;
}

.nav-link:hover {
  color: var(--c-light-accent);
}

.nav-toggle {
  height: 2.5em;
  width: 2.5em;
  background-color: #0000;
  background-image: url("menubars.fe124014.svg");
  background-size: 100% 100%;
  border: none;
  display: none;
}

.nav-toggle.is-active {
  background-image: url("menucross.e01848a8.svg");
}

@media (max-width: 780px) {
  .js .nav-toggle {
    margin-right: var(--spacer);
    display: block;
    position: relative;
  }

  .company-logo {
    margin: var(--spacer) 0;
    margin-left: var(--spacer);
  }

  .navbar {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .nav-menu {
    text-align: start;
    display: inline-block;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .nav-link {
    padding: var(--spacer-medium) 0;
    margin-left: var(--spacer-medium);
    width: 100%;
    display: inline-block;
  }

  .nav-link:hover:after {
    opacity: 0;
  }

  .nav-item {
    padding: 0;
  }

  .nav-item:not(:last-child):after {
    content: "";
    height: 2px;
    background: var(--c-white);
    left: var(--spacer-medium);
    width: 80%;
    opacity: .2;
    display: block;
    position: absolute;
    right: 0;
  }

  .page-header {
    position: relative;
  }

  .js .page-header {
    position: fixed;
  }

  .js .nav-menu {
    display: none;
  }

  @keyframes drop-animate {
    0% {
      transform-origin: top;
      opacity: 0;
      transform: scaleY(0);
    }

    100% {
      transform-origin: top;
      opacity: 1;
      transform: scaleY(1);
    }
  }

  .nav-menu.is-active {
    background-color: var(--c-dark);
    animation: drop-animate .25s;
    display: inline-block;
  }

  .is-not-active {
    filter: grayscale();
  }
}

.scroll-down .page-header {
  transform: translate3d(0, -1000%, 0);
}

.scroll-up .page-header {
  transform: none;
}

.hero {
  height: 80vh;
  background: linear-gradient(var(--c-transparent), var(--c-transparent) ), top 0% left 10% / cover no-repeat url("20201022_111032_cropped.d09957e6.webp");
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.hero-content {
  width: var(--spacer-max-width);
  max-width: 100%;
  padding-left: var(--spacer-medium);
  padding-right: var(--spacer-medium);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero-title {
  color: var(--c-white);
  text-align: center;
  font-family: var(--ff-sans);
  font-weight: var(--fw-thin);
  font-size: var(--fs-large);
  position: relative;
}

.hero-sub {
  color: var(--c-white);
  text-align: center;
  font-family: var(--ff-serif);
  font-weight: var(--fw-normal);
  font-size: var(--fs-small);
  position: relative;
}

.hero-cta {
  margin-top: var(--spacer-xlarge);
  width: 100%;
  font-size: var(--fs-p);
  justify-content: center;
  display: flex;
  position: relative;
}

.hero-cta > * {
  padding: var(--spacer);
  border-radius: var(--border-radius);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.hero-cta > :nth-child(1) {
  text-transform: uppercase;
  background: var(--c-dark-accent);
  color: var(--c-white);
  margin-right: var(--spacer-large);
}

.hero-cta > :nth-child(2) {
  background: var(--c-white);
  color: var(--c-dark);
  font-size: var(--fs-p);
}

@media (max-width: 575px) {
  .hero-cta {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero-cta > * {
    width: 12rem;
  }

  .hero-cta > :nth-child(1) {
    width: 12rem;
    margin-right: 0;
    margin-bottom: var(--spacer-medium);
  }
}

.fade-in {
  animation: fade-in 1.5s ease-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.main-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.article-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.card-wrapper {
  width: 100%;
  justify-content: space-between;
  gap: var(--spacer-xlarge);
  display: flex;
  position: relative;
}

.card {
  background-color: var(--c-medium);
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
  padding: var(--spacer-medium);
  text-align: left;
  color: var(--c-white);
  min-height: 25rem;
  width: 21rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.card-rec {
  min-height: 32rem;
}

.card-rec > img {
  border-radius: 50%;
  align-self: center;
}

.card-icons {
  margin-top: var(--spacer-medium);
}

.card-caption {
  font-family: var(--ff-sans);
  color: var(--c-white);
  font-size: var(--fs-small);
  font-weight: var(--fw-normal);
  margin: var(--spacer-large) 0 var(--spacer-medium) 0;
}

.card-text {
  margin-bottom: var(--spacer-medium);
}

.card-text li {
  font-size: var(--fs-p);
  font-family: var(--ff-p-sans);
  line-height: 1.6;
  font-weight: var(--fw-normal);
  margin-bottom: var(--spacer);
}

.card-icon-container {
  transform: translate(var(--spacer-medium), -50%);
  border: .5rem solid var(--c-medium);
  background-color: var(--c-white);
  border-radius: 50%;
  padding: .5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.card-icon {
  color: var(--c-medium);
  margin: auto;
  font-size: 4rem;
}

.card-img {
  border-radius: var(--border-radius);
  height: 12.5rem;
  width: 12.5rem;
  max-width: 100vw;
  box-shadow: var(--shadow);
  object-fit: cover;
  margin-bottom: var(--spacer-medium);
  overflow: hidden;
}

.card-name {
  font-size: var(--fs-xsmall);
  font-family: var(--ff-sans);
  font-weight: var(--fw-thin);
  margin-bottom: var(--spacer);
  text-transform: capitalize;
  font-style: italic;
}

.card-title {
  font-size: var(--fs-medium);
  font-family: var(--ff-serif);
}

.scrolling-bg {
  --bg-min-height: calc(938 / 3);
  min-height: var(--bg-min-height);
  justify-content: center;
}

.main-content > .scrolling-bg {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.scrolling-bg:before {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(var(--c-transparent), var(--c-transparent) ), bottom 30% left 0 / cover no-repeat url("20210405_170207.c0b63aa7.webp");
  background-attachment: fixed;
  position: absolute;
}

.scrolling-container {
  place-items: center;
  gap: var(--spacer-xlarge);
  max-width: var(--spacer-max-width);
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

.cardless {
  background: var(--c-light);
  color: var(--c-dark);
  box-shadow: none;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.cardless:not(:first-child) {
  color: var(--c-dark-accent);
}

.about-wrapper {
  align-items: center;
  column-gap: var(--spacer-xlarge);
  margin-top: var(--spacer);
  z-index: 10;
  color: var(--c-dark);
  grid-template-columns: 1fr auto;
  display: grid;
}

.about-wrapper > .card-img {
  height: 20rem;
  width: 20rem;
  max-width: 90vw;
  border-radius: 50%;
  grid-row: 1 / 3;
  margin: 0;
}

.about-wrapper > .card-caption {
  color: var(--c-dark);
  margin: 0;
}

.hidden-desktop {
  color: var(--c-dark);
  display: none;
}

.about-wrapper > .card-text {
  margin: 0;
}

.card ul {
  margin: 0;
  list-style: none;
}

.card li:before {
  background-color: var(--c-white);
  content: "";
  height: .375rem;
  width: .375rem;
  border-radius: 50%;
  margin-bottom: .125rem;
  margin-right: .625rem;
  display: inline-block;
}

@media (max-width: 1200px) {
  .about-wrapper {
    width: 100%;
  }

  .about-wrapper > :not(:last-child) {
    margin-bottom: var(--spacer-medium);
  }
}

@media (max-width: 1100px) {
  .about-wrapper {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }

  .about-wrapper > .card-img:first-child {
    flex-direction: column;
    align-self: center;
    display: flex;
  }

  .about-wrapper > .card-caption {
    margin-bottom: 0;
  }

  .hidden-desktop {
    display: inline;
  }

  .hidden-mobile {
    display: none;
  }

  .article-wrapper {
    text-align: left;
    width: 100%;
    padding-left: var(--spacer-large);
    padding-right: var(--spacer-large);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card:nth-child(2n) {
    margin-left: 0;
    margin-right: 0;
  }

  .card-wrapper {
    text-align: left;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
  }
}

@media (max-width: 990px) {
  .scrolling-container {
    grid-template-columns: 1fr 1fr;
  }

  .cardless:first-child {
    grid-column: 1 / -1;
  }

  .card ul {
    padding-left: var(--spacer);
  }

  .card li:before {
    height: 6px;
    width: 6px;
  }
}

@media (max-width: 780px) {
  .card-icons {
    margin-top: 0;
  }

  .card {
    width: 100%;
    max-width: 21rem;
    min-height: 22rem;
  }

  .card-icons > .card:not(:first-child) {
    margin-top: var(--spacer-medium);
  }

  .card-wrapper {
    text-align: left;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    gap: var(--spacer-large);
    flex-flow: column;
  }

  .scrolling-container {
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 0;
    display: flex;
  }

  .scrolling-bg {
    max-height: 80rem;
    justify-content: center;
  }

  .cardless {
    max-width: 21rem;
    min-height: 21rem;
  }

  .cardless:first-child {
    min-height: var(--spacer-xlarge);
  }

  .cardless > .card-text {
    margin: 0;
  }
}

@media (max-width: 575px) {
  .card {
    width: 100%;
  }

  .article-wrapper {
    padding-left: var(--spacer);
    padding-right: var(--spacer);
  }
}

.hidden {
  display: none;
}

.card-contact {
  background-color: var(--c-white);
  color: var(--c-medium);
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  padding: 0;
}

.contact-form {
  width: 100%;
  font-size: var(--fs-p);
  font-family: var(--ff-sans);
  align-items: center;
  gap: var(--spacer);
  display: grid;
}

.label-group {
  gap: var(--spacer);
  width: 100%;
  flex-direction: column;
  display: flex;
}

.form-group {
  gap: var(--spacer-medium);
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(15.625rem, 1fr));
  display: grid;
}

.icon-group {
  width: 100%;
  display: flex;
  position: relative;
}

.grid-span {
  text-align: center;
}

.form-input {
  font-size: var(--fs-p);
  font-family: var(--ff-p-sans);
  border-radius: var(--border-radius);
  border-width: .125rem;
  border-color: var(--c-medium);
  padding: var(--spacer);
  background-color: var(--c-white);
  color: var(--c-medium);
  width: 100%;
  border-style: solid;
}

.label-group .card-caption {
  color: var(--c-medium);
  margin: 0;
}

.form-input:focus {
  background-color: var(--c-light);
  outline: 3px solid var(--c-medium);
  color: var(--c-dark);
}

.label-group .form-message, .form-error {
  color: var(--c-medium);
  height: var(--spacer-medium);
  font-style: italic;
  display: block;
}

.label-group.error .form-message {
  color: var(--c-error);
}

.label-group.error .form-input {
  border-color: var(--c-error);
}

.label-group.success .form-input {
  border-color: var(--c-success);
}

.label-group.error .form-input:focus {
  outline: 3px solid var(--c-error);
}

.label-group.success .form-input:focus {
  outline: 3px solid var(--c-success);
}

.form-icon {
  position: absolute;
  top: 50%;
  right: .3rem;
  transform: translateY(-50%);
}

.icon-error {
  color: var(--c-error);
  display: none;
}

.icon-success {
  color: var(--c-success);
  display: none;
}

.label-group.success .icon-success, .label-group.error .icon-error {
  display: block;
}

.icon-group svg {
  height: calc(var(--fs-p)  + var(--fs-p) );
  width: calc(var(--fs-p)  + var(--fs-p) );
}

.textarea {
  text-align: start;
}

.contact-form button {
  cursor: pointer;
  border-radius: var(--border-radius);
  color: var(--c-medium);
  font-family: var(--ff-sans);
  font-size: var(--fs-small);
  padding: var(--spacer);
  width: 33.5rem;
  justify-self: center;
}

.button:hover {
  background: var(--c-white);
  color: var(--c-dark);
}

@media (max-width: 575px) {
  .contact-form {
    flex-direction: column;
    display: flex;
  }

  .card-contact {
    padding-inline: var(--spacer);
  }

  .contact-form button {
    text-align: center;
    width: 100%;
  }

  .grid-span {
    text-align: left;
  }

  .label-group .form-message, .form-error {
    height: var(--spacer);
  }

  .form-group {
    gap: var(--spacer);
  }
}

.submit-wrapper {
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.submit-body {
  font-family: var(--ff-sans);
  background: var(--c-dark);
  color: var(--c-white);
  overflow: hidden;
}

.submit-header {
  font-size: var(--fs-small);
}

.submit-card {
  width: 75%;
  max-width: 21rem;
  padding: var(--spacer-medium);
  background: var(--c-white);
  color: var(--c-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  gap: var(--spacer);
  flex-direction: column;
  display: flex;
  position: relative;
}

#back-link {
  font-weight: var(--fw-bold);
  color: var(--c-medium);
  margin: 0;
  text-decoration: none;
}

#back-link svg {
  position: relative;
  top: 2px;
}

#back-link:hover, #back-link:focus {
  text-underline-offset: .125rem;
  text-decoration: underline;
  text-decoration-color: var(--c-light-accent);
}

#back-link:hover svg path {
  fill: var(--c-medium);
}

.gallery {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: 200px 200px;
  grid-auto-flow: dense;
  gap: var(--spacer-medium);
  max-width: var(--spacer-max-width);
  border-radius: var(--border-radius);
  width: 100%;
  display: grid;
}

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
}

@media (min-width: 480px) {
  .gallery-item:first-child {
    grid-area: 1 / 1 / span 2 / span 2;
  }

  .gallery-item:nth-child(3n) {
    grid-column: span 2;
  }

  .gallery-item:nth-child(6n) {
    grid-area: span 2 / span 2;
  }
}

.footer-wrapper {
  padding: var(--spacer-large);
  background-color: var(--c-dark);
  color: var(--c-white);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-content {
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacer-large);
  margin-block-end: var(--spacer-large);
  max-width: var(--spacer-max-width);
  display: flex;
}

.footer-title {
  font-family: var(--ff-sans);
  color: var(--c-white);
  font-size: var(--fs-small);
}

.footer-list, .footer-item {
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacer-medium);
  flex-direction: column;
  list-style: none;
  display: flex;
  position: relative;
}

.footer-item:not(:last-child) :after {
  content: "";
  height: 1px;
  background: var(--c-white);
  bottom: calc(var(--spacer)  * -1);
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.icon {
  font-size: var(--fs-p);
  margin-right: var(--spacer);
}

.footer-link {
  align-items: center;
  display: flex;
}

.credit {
  justify-content: center;
  align-items: center;
  gap: var(--spacer-large);
  font-size: var(--fs-xsmall);
  display: flex;
}

@media (max-width: 780px) {
  .footer-wrapper {
    padding: var(--spacer-large) 0;
  }

  .footer-content {
    gap: var(--spacer-medium);
    flex-direction: column;
  }

  .credit {
    gap: var(--spacer);
    flex-direction: column;
  }

  .footer-item:not(:last-child):after {
    bottom: calc(var(--spacer)  * -.5);
  }
}

.floating-wrapper {
  text-align: center;
  color: var(--c-white);
  text-transform: uppercase;
  z-index: 100;
  margin: 0 auto;
  padding: 0;
  transition: transform .4s;
  display: inline-block;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 1000%, 0);
}

.floating-list {
  padding: var(--spacer) 0;
  width: 100%;
  gap: var(--spacer-large);
  grid-template-columns: 1fr 1fr;
  align-items: center;
  list-style: none;
  display: grid;
}

.floating-item {
  background-color: var(--c-dark);
  text-align: center;
  border-radius: var(--border-radius);
  padding: var(--spacer);
}

.floating-item:hover {
  background-color: var(--c-dark-accent);
}

.floating-link:hover {
  color: var(--c-white);
}

.scroll-down .floating-wrapper {
  transform: translateX(-50%);
}

.scroll-up .floating-wrapper {
  transform: translate3d(-50%, 1000%, 0);
}

/*# sourceMappingURL=form-submission.f10f347f.css.map */
